

const UpperNavLoc = ()=>{
    return(
        <div className='d-flex flex-column justify-content-center align-items-center'>
          
        <select  id='id-select-location'class="form-select ">
             <option selected>Redwood City, CA-94063</option>
        </select>
      
        </div>
    )
}

export default UpperNavLoc;