
import BannerImg from "./BannerImg";
import CategoriesPage from "./CategoriesPage";

import ImagesComp from "./ImagesComp";
import PartyServices from "./PartyServices";



import PopularCategories from "./PopularCategories";




const LandingPage = ()=>{
   
    return(
        <>
    
    
    <PartyServices/>
    
  
      
      
       
       

       </>
    )
    
}
export default LandingPage;